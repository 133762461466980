import { template as template_6027d4141f9148e6a5f0cbdc7eaf2edf } from "@ember/template-compiler";
const FKText = template_6027d4141f9148e6a5f0cbdc7eaf2edf(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
