import { template as template_33155e3725a649f5a1c7b18de8ce627d } from "@ember/template-compiler";
const SidebarSectionMessage = template_33155e3725a649f5a1c7b18de8ce627d(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
