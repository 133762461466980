import { template as template_e5a7351943dd4a6b9a085083d55d9264 } from "@ember/template-compiler";
const WelcomeHeader = template_e5a7351943dd4a6b9a085083d55d9264(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
