import { template as template_b86909b3ca2c46e1a3d1f59fddffd78c } from "@ember/template-compiler";
const FKLabel = template_b86909b3ca2c46e1a3d1f59fddffd78c(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
